export default class Consignacao {
  constructor(
    id = null,
    anoReferencia = null,
    mesReferencia = null,
    numeroContrato = null,
    observacoes = null,
    prazoRestante = null,
    prazoTotal = null,
    valorParcela = null,
    certidao = null,
    ip = null,
    alteradoPor = null,
    cadastradoPor = {},
    matricula = {},
    rubrica = {},
    statusConsignacao = null,
    statusConsignacaoDto = {},
    consignacaoRenegociacao = {},
    auditMetadata = {
      cadastradoEm: '',
    },
    carencia = 0,
    propostaConsignacao = {
      cadastradoPor: '',
    },
  ) {
    this.id = id
    this.anoReferencia = anoReferencia
    this.mesReferencia = mesReferencia
    this.numeroContrato = numeroContrato
    this.observacoes = observacoes
    this.prazoRestante = prazoRestante
    this.prazoTotal = prazoTotal
    this.valorParcela = valorParcela
    this.ip = ip
    this.certidao = certidao
    this.matricula = matricula
    this.rubrica = rubrica
    this.statusConsignacao = statusConsignacao
    this.statusConsignacaoDto = statusConsignacaoDto
    this.alteradoPor = alteradoPor
    this.cadastradoPor = cadastradoPor
    this.consignacaoRenegociacao = consignacaoRenegociacao
    this.auditMetadata = auditMetadata
    this.carencia = carencia
    this.propostaConsignacao = propostaConsignacao
  }
}
