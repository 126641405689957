export default class HistoricoConsignacaoService {
  constructor(http) {
    this._http = http
  }

  async getHistoricoConsignacao(id) {
    const { data } = await this._http.get(
      `/api/historico_consignacao/consignacao/${id}`,
    )
    return data
  }
}
